import React from 'react';
import { Link } from 'react-router-dom';
import './MainB2.css'; // Asegúrate de que la ruta sea correcta
import softwareIcon from '../Assets/software.png'; // Asegúrate de que la ruta sea correcta
import securityIcon from '../Assets/security.png'; // Asegúrate de que la ruta sea correcta
import trainingIcon from '../Assets/training.png'; // Asegúrate de que la ruta sea correcta
import libraryIcon from '../Assets/library.png'; // Asegúrate de que la ruta sea correcta
import calendarIcon from '../Assets/calendar.png'; // Asegúrate de que la ruta sea correcta

const MainB2 = () => {
  return (
    <div className="services-container">
      <Link to="/desarrollo-de-software" className="service-item">
        <img src={softwareIcon} alt="Consultoría de Software" className="service-icon" />
        <p>DESARROLLO DE SOFTWARE</p>
      </Link>
      <Link to="/seguridad-industrial" className="service-item">
        <img src={securityIcon} alt="Consultoría de Seguridad Industrial" className="service-icon" />
        <p>SEGURIDAD INDUSTRIAL</p>
      </Link>
      <Link to="/capacitaciones" className="service-item">
        <img src={trainingIcon} alt="Capacitaciones" className="service-icon" />
        <p>CAPACITACIONES</p>
      </Link>
      <Link to="/biblioteca" className="service-item">
        <img src={libraryIcon} alt="Biblioteca" className="service-icon" />
        <p>BIBLIOTECA</p>
      </Link>
      <Link to="/calendario" className="service-item">
        <img src={calendarIcon} alt="Calendario" className="service-icon" />
        <p>CALENDARIO</p>
      </Link>
    </div>
  );
};

export default MainB2;

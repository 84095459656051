import React from 'react';
import './StylesB.css';

const Category = ({ title, documents }) => {
  return (
    <div className="category-container">
      <h2>{title}</h2>
      <div className="documents-list">
        {documents.map((doc, index) => (
          <a key={index} href={doc.filePath} target="_blank" rel="noopener noreferrer" className="document-link">
            <div className="document-card">
              <h3>{doc.title}</h3>
              <p>{doc.description}</p>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default Category;

import React, { useState } from 'react';
import { FaLaptopCode, FaUserFriends, FaGlobe, FaMapMarkerAlt, FaLightbulb, FaHandshake } from 'react-icons/fa';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { ref, push } from 'firebase/database';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { database, storage } from '../../firebase';
import './JoinUs.css';
import remoteWorkImage from '../Assets/remote.png'; // Replace with actual path to the image
import Footer from '../Front/footer';


const JoinUs = () => {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    nombre: '',
    apellidoPaterno: '',
    apellidoMaterno: '',
    dni: '',
    email: '',
    telefono: '',
    cv: null
  });
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, cv: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const newRef = push(ref(database, 'candidatos'));
    const cvRef = storageRef(storage, `cvs/${newRef.key}_${formData.cv.name}`);
    await uploadBytes(cvRef, formData.cv);
    const cvUrl = await getDownloadURL(cvRef);

    await push(newRef, { ...formData, cv: cvUrl });

    setLoading(false);
    setSuccessMessage(true);
    setTimeout(() => {
      setSuccessMessage(false);
      setShowModal(false);
    }, 3000);
    setFormData({
      nombre: '',
      apellidoPaterno: '',
      apellidoMaterno: '',
      dni: '',
      email: '',
      telefono: '',
      cv: null
    });
  };

  return (
    <div>
    <div className="join-us-container">
      <div className="banner-section">
        <div className="banner-text">
          <h1>Trabaja con Nosotros</h1>
          <p>Únete a nuestro equipo y trabaja de manera remota desde cualquier lugar del Mundo.</p>
          <Button className="apply-button" onClick={() => setShowModal(true)}>Aplica Ahora</Button>
        </div>
        <div className="banner-image">
          <img src={remoteWorkImage} alt="Remote Work" />
        </div>
      </div>

      <div className="work-method-section">
        <h2>Nuestra Forma de Trabajo</h2>
        <h3>Enfocados en la productividad y el bienestar de nuestro equipo.</h3>
        <div className="work-methods">
          <div className="work-method-item">
            <FaLaptopCode size={50} color="#2D7CA0" />
            <h4>Trabajo Remoto</h4>
            <p>Conéctate y colabora desde la comodidad de tu hogar.</p>
          </div>
          <div className="work-method-item">
            <FaUserFriends size={50} color="#2D7CA0" />
            <h4>Equipo Colaborativo</h4>
            <p>Formamos equipos que trabajan juntos hacia un objetivo común.</p>
          </div>
          <div className="work-method-item">
            <FaGlobe size={50} color="#2D7CA0" />
            <h4>Proyectos Internacionales</h4>
            <p>Trabajamos en proyectos que tienen impacto global.</p>
          </div>
          <div className="work-method-item">
            <FaMapMarkerAlt size={50} color="#2D7CA0" />
            <h4>Desde Bolivia para el Mundo</h4>
            <p>Estamos orgullosos de nuestras raíces y llevamos el talento boliviano al mundo.</p>
          </div>
        </div>
      </div>

      <div className="values-section">
        <h2>Nuestros Valores</h2>
        <div className="values">
          <div className="value-item">
            <FaUserFriends size={40} color="#2D7CA0" />
            <h3>Trabajo en Equipo</h3>
            <p>Colaboramos estrechamente y valoramos cada aportación.</p>
          </div>
          <div className="value-item">
            <FaLightbulb size={40} color="#2D7CA0" />
            <h3>Innovación</h3>
            <p>Buscamos constantemente nuevas ideas para mejorar.</p>
          </div>
          <div className="value-item">
            <FaHandshake size={40} color="#2D7CA0" />
            <h3>Integridad</h3>
            <p>Mantenemos altos estándares éticos en todo lo que hacemos.</p>
          </div>
        </div>
      </div>

      <div className="cta-section">
        <h2>¿Estás listo para unirte a nosotros?</h2>
        <p>Aplica hoy y sé parte de un equipo dinámico y apasionado.</p>
        <Button className="cta-button" onClick={() => setShowModal(true)}>Únete Ahora</Button>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Formulario de Aplicación</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <div className="text-center">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Cargando...</span>
              </Spinner>
            </div>
          ) : successMessage ? (
            <div className="text-center">
              <h4>¡Registro Solicitado Exitosamente!</h4>
            </div>
          ) : (
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formNombre">
                <Form.Label>Nombre</Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  name="nombre"
                  value={formData.nombre}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formApellidoPaterno">
                <Form.Label>Apellido Paterno</Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  name="apellidoPaterno"
                  value={formData.apellidoPaterno}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formApellidoMaterno">
                <Form.Label>Apellido Materno</Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  name="apellidoMaterno"
                  value={formData.apellidoMaterno}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formDNI">
                <Form.Label>DNI o CI</Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  name="dni"
                  value={formData.dni}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formEmail">
                <Form.Label>Correo Electrónico</Form.Label>
                <Form.Control
                  type="email"
                  placeholder=""
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formTelefono">
                <Form.Label>Teléfono o Whatsapp</Form.Label>
                <Form.Control
                  type="tel"
                  placeholder=""
                  name="telefono"
                  value={formData.telefono}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formCV">
                <Form.Label>Curriculum Vitae (CV)</Form.Label>
                <Form.Control
                  type="file"
                  name="cv"
                  onChange={handleFileChange}
                  required
                />
              </Form.Group>
              <Button variant="primary" type="submit" className="mt-3">
                Enviar
              </Button>
            </Form>
          )}
        </Modal.Body>
      </Modal>
    </div>
    <Footer />
    </div>
  );
};

export default JoinUs;

import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyCy6G-LdD54Fw3Atc4PdcHr_nIkODjaGP0",
  authDomain: "chakuyweb.firebaseapp.com",
  projectId: "chakuyweb",
  storageBucket: "chakuyweb.appspot.com",
  messagingSenderId: "513808125591",
  appId: "1:513808125591:web:cbfe6888cf0574c924a3d2"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const database = getDatabase(app);
const storage = getStorage(app); // Añadido para el almacenamiento
const googleProvider = new GoogleAuthProvider();

export { auth, database, storage, googleProvider };

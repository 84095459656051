import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './header.css';
import logo from '../Assets/logo.png'; // Asegúrate de que esta ruta sea correcta
import { FaBars, FaTimes } from 'react-icons/fa';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <header>
      <nav>
        <Link to="/" className="logo-link">
          <img src={logo} alt="Chakuy Logo" className="logo" />
        </Link>
        <div className="menu-icon" onClick={toggleMenu}>
          {isOpen ? <FaTimes /> : <FaBars />}
        </div>
        <ul className={isOpen ? 'nav-links open' : 'nav-links'}>
          <li><Link to="/" onClick={closeMenu}>INICIO</Link></li>
          <li><Link to="/about" onClick={closeMenu}>ACERCA DE NOSOTROS</Link></li>
          <li><Link to="/unete" onClick={closeMenu}>TRABAJA CON NOSOTROS</Link></li>
          <li><Link to="/contact" onClick={closeMenu}>CONTACTANOS</Link></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;

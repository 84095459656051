import React, { useEffect, useState } from 'react';
import { ref, onValue } from 'firebase/database';
import { getDownloadURL, ref as storageRef } from 'firebase/storage';
import { database, storage } from '../../firebase'; // Asegúrate de que la ruta sea correcta
import './Calendario.css'; // Asegúrate de que la ruta sea correcta
import Footer from '../Front/footer';

const Calendario = () => {
  const [activities, setActivities] = useState([]);

  useEffect(() => {
    const fetchActivities = async () => {
      const dbRef = ref(database, 'Calendario'); // Asegúrate de que la ruta sea correcta
      onValue(dbRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const activitiesArray = Object.keys(data).map(async (key) => {
            const activity = data[key];
            if (activity.Estado === "Activo") {
              const imageUrl = await getDownloadURL(storageRef(storage, activity.Imagen));
              return { ...activity, imageUrl, id: key };
            }
            return null;
          });
          Promise.all(activitiesArray).then(filteredActivities => {
            setActivities(filteredActivities.filter(activity => activity !== null));
          });
        }
      });
    };

    fetchActivities();
  }, []);

  return (
    <div>
      <div className="calendario-container">
        {activities.map((activity, index) => (
          <div key={index} className="activity-card">
            <h2 className="activity-title">{activity.id}</h2>
            <div className="activity-content">
              <img src={activity.imageUrl} alt={activity.Descripcion} className="activity-image" />
              <div className="activity-details">
                <p><strong>Fecha de Inicio:</strong> {activity['Fecha Inicio']}</p>
                <p><strong>Fecha Fin:</strong> {activity['Fecha Fin']}</p>
                <p><strong>Descripción:</strong> {activity.Descripcion}</p>
                <p><strong>Costo:</strong> {activity.Costo} Bs</p>
                <p><strong>Inscripción:</strong> <a href={activity.Inscripcion} target="_blank" rel="noopener noreferrer">Link de Inscripción</a></p>
                <p><strong>Modalidad:</strong> {activity.Modalidad} </p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <br></br>
   <br></br>
   <br></br>
   <br></br>
   <p></p>
   <br></br>
   <br></br>
   <br></br>
   <br></br>
   <p></p>
   <br></br>
   <br></br>
   <br></br>
   <br></br>
   <p></p>
   <br></br>
   <br></br>
   <br></br>
   <br></br>
   <p></p>
      <Footer />
    </div>
  );
};

export default Calendario;

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './Components/Front/header';
import Footer from './Components/Front/footer';
import Home from './Components/Front/home'
import About from './Components/Front/about';
import Services from './Components/Front/services';
import Contact from './Components/Front/contact';
import MainSection from './Components/Front/MainSection';
import NovedadDetalle from './Components/Front/NovedadDetalle';
import CardDetail from './Components/Front/CardDetail';
import JoinUs from './Components/Front/JoinUs';
import Calendario from './Components/Actividades/Calendario';
import Biblioteca from './Components/Biblioteca/Biblioteca';
import Capacitaciones from './Components/Actividades/Capacitaciones';
import SegInd from './Components/Actividades/SeguridadIndustrial';
import Software from './Components/DesarrolloSoftware/DesarrolloSoftware';
import MainB2 from './Components/Front/MainB2';


import './App.css';

const App = () => {
  return (
    <Router>
      <Header />
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/main" element={<MainSection />} />
          <Route path="/novedades/:id" element={<NovedadDetalle />} />
          <Route path="/card_detalle/:id" element={<CardDetail />} />
          <Route path="/calendario" element={<Calendario />} />
          <Route path="/biblioteca" element={<Biblioteca />} />
          <Route path="/capacitaciones" element={<Capacitaciones />} />
          <Route path="/seguridad-industrial" element={<SegInd />} />
          <Route path="/desarrollo-de-software" element={<Software />} />
          <Route path="/unete" element={<JoinUs />} />
        </Routes>
      </main>
    
     
    </Router>
     
  );
};

export default App;

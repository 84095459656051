import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ref, onValue } from 'firebase/database';
import { getDownloadURL, ref as storageRef } from 'firebase/storage';
import { database, storage } from '../../firebase'; // Asegúrate de que la ruta sea correcta
import { FaFacebook, FaWhatsapp } from 'react-icons/fa'; // Importar íconos
import './CardDetail.css';

const CardDetail = () => {
  const { id } = useParams();
  const [card, setCard] = useState(null);

  useEffect(() => {
    const fetchCard = async () => {
      const dbRef = ref(database, `cards/${id}`);
      onValue(dbRef, async (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const imageUrl = await getDownloadURL(storageRef(storage, data.image));
          setCard({ ...data, imageUrl });
        }
      });
    };

    fetchCard();
  }, [id]);

  if (!card) return <div>Cargando...</div>;

  const shareUrl = window.location.href;
  const shareText = `Mira esta Noticia: ${card.title}`;

  return (
    <div className="card-detail-container">
      <img src={card.imageUrl} alt={card.title} className="card-detail-image" />
      <div className="card-detail-content">
        <h1>{card.title}</h1>
        <p className="card-detail-date">{card.date}</p>
        <p>{card.description}</p>
        <div className="card-detail-completo">
          {card.completo ? card.completo.split('\n').map((para, index) => (
            <p key={index}>{para}</p>
          )) : <p>No hay información adicional.</p>}
        </div>
        {card.organizadores && (
          <div className="card-detail-organizadores">
            <h3>Organizadores:</h3>
            <ul>
              {Object.keys(card.organizadores).map((org, index) => (
                <li key={index}>{org}</li>
              ))}
            </ul>
          </div>
        )}
        {card.organizadores && (
          <div className="card-detail-organizadores-imagenes">
            <div className="imagenes-container">
              {Object.values(card.organizadores).map((imageUrl, index) => (
                <img key={index} src={imageUrl} alt={`Organizador ${index + 1}`} className="organizador-image" />
              ))}
            </div>
          </div>
        )}
        {card.fuente && (
          <div className="card-detail-fuente">
            <a href={card.fuente} target="_blank" rel="noopener noreferrer">
              Leer más
            </a>
          </div>
        )}
        {card.link && (
          <div className="card-detail-link">
            <a href={card.link} target="_blank" rel="noopener noreferrer">
              Visitar el Enlace
            </a>
          </div>
        )}
        <div className="share-buttons">
          <a 
            href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`} 
            target="_blank" 
            rel="noopener noreferrer"
            title="Compartir en Facebook"
          >
            <FaFacebook size={32} color="#3b5998" />
          </a>
          <a 
            href={`https://wa.me/?text=${encodeURIComponent(shareText)}%20${encodeURIComponent(shareUrl)}`} 
            target="_blank" 
            rel="noopener noreferrer"
            title="Compartir en WhatsApp"
          >
            <FaWhatsapp size={32} color="#25D366" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default CardDetail;

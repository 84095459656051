import React, { useState } from 'react';
import './Contact.css'; // Asegúrate de que la ruta sea correcta
import Footer from '../Front/footer';

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const phoneNumber = "+59170776212"; // Reemplaza con el número de teléfono al que quieres enviar el mensaje
    const textMessage = `Nombre: ${name}\nEmail: ${email}\nMensaje: ${message}`;
    window.open(`https://wa.me/${phoneNumber}?text=${encodeURIComponent(textMessage)}`, '_blank');
  };

  return (
    <div>
    <div className="contact-page">
      <div className="contact-container">
        <h1 className="contact-title">Contáctanos</h1>
        <p className="contact-subtitle">Realiza tus preguntas o inquietudes</p>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Nombre:</label>
            <input
              type="text"
              className="form-control"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Correo:</label>
            <input
              type="email"
              className="form-control"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Mensaje:</label>
            <textarea
              className="form-control"
              id="message"
              rows="6"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            ></textarea>
          </div>
          <button type="submit" className="btn btn-primary mt-3">
            Enviar Mensaje
          </button>
        </form>
      </div>
    </div>
    <Footer />
    </div>
  );
};

export default Contact;

import React, { useEffect, useState } from 'react';
import { ref, onValue, push } from 'firebase/database';
import { getDownloadURL, ref as storageRef } from 'firebase/storage';
import { database, storage } from '../../firebase'; // Asegúrate de que la ruta sea correcta
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Footer from '../Front/footer';
import './Capacitaciones.css';

const Capacitaciones = () => {
  const [courses, setCourses] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [formData, setFormData] = useState({
    nombre: '',
    email: '',
    telefono: '',
    curso: '',
    fecha: new Date().toLocaleString()
  });

  useEffect(() => {
    const fetchCourses = async () => {
      const dbRef = ref(database, 'Capacitaciones'); // Asegúrate de que la ruta sea correcta
      onValue(dbRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const coursesArray = Object.keys(data).map(async (key) => {
            const course = data[key];
            const imageUrl = await getDownloadURL(storageRef(storage, course.image));
            return { ...course, imageUrl, id: key };
          });
          Promise.all(coursesArray).then(setCourses);
        }
      });
    };

    fetchCourses();
  }, []);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    // Validación para permitir solo números en el campo de teléfono
    if (name === 'telefono') {
      const regex = /^[0-9\b]+$/;
      if (value === '' || regex.test(value)) {
        setFormData({ ...formData, [name]: value });
      }
    } else {
      setFormData({
        ...formData,
        [name]: type === 'checkbox' ? checked : value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const preregistrationRef = ref(database, 'Preregistrations');
    await push(preregistrationRef, formData);
    setFormData({ nombre: '', email: '', telefono: '', curso: '', fecha: new Date().toLocaleString() });
    setShowModal(false);
    setSuccessModal(true);
  };

  const handleRegisterClick = (courseTitle) => {
    setFormData({ ...formData, curso: courseTitle });
    setShowModal(true);
  };

  return (
    <div>
    <div className="capacitaciones-container">
      {courses.map((course, index) => (
        <div key={index} className="course-card">
          <img src={course.imageUrl} alt={course.title} className="course-image" />
          <div className="course-content">
            <h3 className="course-title">{course.title}</h3>
            <p className="course-description">{course.description}</p>
            <p className="course-instructor"><strong>Instructor:</strong> {course.instructor}</p>
            <p className="course-duration"><strong>Duración:</strong> {course.duration}</p>
            <Button className="register-button" onClick={() => handleRegisterClick(course.title)}>
              Regístrate
            </Button>
          </div>
        </div>
      ))}

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Formulario de Preregistro</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formNombre">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nombre"
                name="nombre"
                value={formData.nombre}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formTelefono">
              <Form.Label>Teléfono</Form.Label>
              <Form.Control
                type="tel"
                placeholder="Teléfono"
                name="telefono"
                value={formData.telefono}
                onChange={handleInputChange}
                required
                pattern="[0-9]*"
                inputMode="numeric"
              />
            </Form.Group>
            <Form.Group controlId="formFecha">
              <Form.Label>Fecha de Registro</Form.Label>
              <Form.Control
                type="text"
                name="fecha"
                value={formData.fecha}
                readOnly
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3">
              Enviar
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={successModal} onHide={() => setSuccessModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Preregistro Exitoso</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Preregistro realizado exitosamente.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setSuccessModal(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <p></p>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <p></p>
    <br></br>
    <br></br>
    
    <p></p>
    <Footer />
    </div>
  );
};

export default Capacitaciones;
import React from 'react';
import './footer.css';
import { FaLinkedin, FaFacebook, FaTwitter, FaYoutube, FaPinterest,FaInstagram } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="social-icons">
        <a href="https://www.linkedin.com/company/64759568" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
        <a href="https://www.facebook.com/chakuybo" target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
        <a href="https://www.instagram.com/chakuybo/" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
       {/* <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer"><FaYoutube /></a>
        <a href="https://www.pinterest.com" target="_blank" rel="noopener noreferrer"><FaPinterest /></a> */}
      </div>
      <p>&copy; 2024 Chakuy. Derechos Reservados.</p>
      <div className="responsible-communication">
        <img src={require('../Assets/logo.png')} alt="Responsible Communication" />
        <p></p>
      </div>
    </footer>
  );
};

export default Footer;

import React, { useEffect, useState } from 'react';
import { ref, onValue, push } from 'firebase/database';
import { getDownloadURL, ref as storageRef } from 'firebase/storage';
import { database, storage } from '../../firebase'; // Asegúrate de que la ruta sea correcta
import { Modal, Button } from 'react-bootstrap'; // Importar componentes de Bootstrap
import './SeguridadIndustrial.css';

const SeguridadIndustrial = () => {
  const [courses, setCourses] = useState([]);
  const [form, setForm] = useState({ nombre: '', curso: '', telefono: '', comentario: '' });
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    const fetchCourses = async () => {
      const dbRef = ref(database, 'SeguridadIndustrial'); // Asegúrate de que la ruta sea correcta
      onValue(dbRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const coursesArray = Object.keys(data).map(async (key) => {
            const course = data[key];
            const imageUrl = await getDownloadURL(storageRef(storage, course.image));
            return { ...course, imageUrl, id: key };
          });
          Promise.all(coursesArray).then(setCourses);
        }
      });
    };

    fetchCourses();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const cotizacionRef = ref(database, 'Cotizaciones');
    push(cotizacionRef, form).then(() => {
      setForm({ nombre: '', curso: '', telefono: '', comentario: '' });
      setModalIsOpen(true);
    });
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className="seguridad-industrial-container">
      {courses.map((course, index) => (
        <div key={index} className="course-card">
          <img src={course.imageUrl} alt={course.title} className="course-image" />
          <div className="course-content">
            <h2 className="course-title">{course.title}</h2>
            <p className="course-description"><strong>Descripción:</strong> {course.description}</p>
            <p className="course-duration"><strong>Carga Horaria:</strong> {course.duration}</p>
            <p className="course-info"><strong>Información:</strong> {course.info}</p>
            <ul className="course-details">
              {course.details.split('\n').map((detail, idx) => (
                <li key={idx}>{detail}</li>
              ))}
            </ul>
          </div>
        </div>
      ))}

      <div className="cotizacion-form-container">
        <h2>Solicita tu Cotización</h2>
        <form onSubmit={handleSubmit}>
          <label>
            Nombre:
            <input type="text" name="nombre" value={form.nombre} onChange={handleChange} required />
          </label>
          <label>
            Curso:
            <input type="text" name="curso" value={form.curso} onChange={handleChange} required />
          </label>
          <label>
            Teléfono de Contacto:
            <input type="tel" name="telefono" value={form.telefono} onChange={handleChange} required />
          </label>
          <label>
            Comentario:
            <textarea name="comentario" value={form.comentario} onChange={handleChange} required></textarea>
          </label>
          <button type="submit" className="btn btn-primary">Enviar</button>
        </form>
      </div>

      <Modal show={modalIsOpen} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Registro Solicitado</Modal.Title>
        </Modal.Header>
        <Modal.Body>Su solicitud de cotización ha sido registrada con éxito.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SeguridadIndustrial;

import React, { useEffect, useState } from 'react';
import { ref, onValue } from 'firebase/database';
import { getDownloadURL, ref as storageRef } from 'firebase/storage';
import { database, storage } from '../../firebase'; // Asegúrate de que la ruta sea correcta
import { useNavigate } from 'react-router-dom';
import './Novedades.css'; // Asegúrate de que la ruta sea correcta

const Novedades = () => {
  const [novedades, setNovedades] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchNovedades = async () => {
      const dbRef = ref(database, 'novedades'); // Asegúrate de que la ruta sea correcta
      onValue(dbRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const novedadesArray = Object.keys(data).map(async (key) => {
            const novedad = data[key];
            if (novedad.estado === 1) {
              const imageUrl = await getDownloadURL(storageRef(storage, novedad.image));
              return { id: key, ...novedad, imageUrl };
            }
            return null;
          });
          Promise.all(novedadesArray).then((results) => {
            const filteredResults = results.filter((result) => result !== null);
            setNovedades(filteredResults);
          });
        }
      });
    };

    fetchNovedades();
  }, []);

  const handleCardClick = (id) => {
    navigate(`/novedades/${id}`);
  };

  return (
    <div className="novedades-container">
      {novedades.length > 0 && (
        <div className="main-novedad-card" onClick={() => handleCardClick(novedades[0].id)}>
          <img src={novedades[0].imageUrl} alt={novedades[0].title} className="main-novedad-image" />
          <div className="main-novedad-content">
            <h2>{novedades[0].title}</h2>
            <p>{novedades[0].description}</p>
          </div>
        </div>
      )}
      <div className="side-novedades">
        {novedades.slice(1).map((novedad, index) => (
          <div key={index} className="side-novedad-card" onClick={() => handleCardClick(novedad.id)}>
            <img src={novedad.imageUrl} alt={novedad.title} className="side-novedad-image" />
            <div className="side-novedad-content">
              <h3>{novedad.title}</h3>
              <p>{novedad.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Novedades;

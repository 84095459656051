import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ref, onValue } from 'firebase/database';
import { getDownloadURL, ref as storageRef } from 'firebase/storage';
import { database, storage } from '../../firebase'; // Asegúrate de que la ruta sea correcta
import './Cards.css';

const Cards = () => {
  const [cardsData, setCardsData] = useState([]);

  useEffect(() => {
    const fetchCardsData = async () => {
      const dbRef = ref(database, 'cards'); // Asegúrate de que la ruta sea correcta
      onValue(dbRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const cardsArray = Object.keys(data).map(async (key) => {
            const card = data[key];
            const imageUrl = await getDownloadURL(storageRef(storage, card.image));
            return { ...card, imageUrl, id: key };
          });
          Promise.all(cardsArray).then(setCardsData);
        }
      });
    };

    fetchCardsData();
  }, []);

  return (
    <div className="cards-container">
      {cardsData.map((card, index) => (
        <Link key={index} to={`/card_detalle/${card.id}`} className="card-link">
          <div className="card">
            <img src={card.imageUrl} alt={card.title} className="card-image" />
            <div className="card-content">
              <p className="card-date">{card.date}</p>
              <h3 className="card-title">{card.title}</h3>
              <p className="card-description">{card.description}</p>
              <span className="card-news-link">Leer Más</span>
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default Cards;

import React from 'react';
import whatsappImage from '../Assets/whatsapp.png'; // Asegúrate de que la ruta sea correcta

const WhatsAppButton = () => {
  const handleButtonClick = () => {
    const phoneNumber = "+59170776212"; // Reemplaza con el número de teléfono al que quieres enviar el mensaje
    const message = "Hola, tengo una consulta."; // Reemplaza con el mensaje que deseas enviar
    window.open(`https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`, '_blank');
  };

  return (
    <div style={{ margin: '20px' }}>
      <img 
        src={whatsappImage} 
        alt="WhatsApp" 
        style={{ cursor: 'pointer', width: '50px', height: '50px' }} 
        onClick={handleButtonClick} 
      />
    </div>
  );
};

export default WhatsAppButton;

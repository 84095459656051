import React, { useState, useEffect } from 'react';
import SearchBar from './SearchBar';
import Category from './Category';
import { ref, onValue } from 'firebase/database';
import { database } from '../../firebase'; // Asegúrate de que la ruta sea correcta
import './StylesB.css';

const Biblioteca = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [documents, setDocuments] = useState({
    normas: [],
    guias: [],
    manuales: [],
    procedimientos: [],
    protocolos: [],
  });

  useEffect(() => {
    const fetchDocuments = async () => {
      const dbRef = ref(database, 'Biblioteca'); // Asegúrate de que la ruta sea correcta
      onValue(dbRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const categories = Object.keys(data);
          const newDocs = categories.reduce((acc, category) => {
            acc[category] = Object.values(data[category]);
            return acc;
          }, {
            normas: [],
            guias: [],
            manuales: [],
            procedimientos: [],
            protocolos: [],
          });
          setDocuments(newDocs);
        }
      });
    };

    fetchDocuments();
  }, []);

  const filteredDocs = (category) => {
    return (documents[category] || []).filter((doc) =>
      doc.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      doc.description.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  return (
    <div className="biblioteca-container">
      <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      <Category title="Normas" documents={filteredDocs('normas')} />
      <Category title="Guías" documents={filteredDocs('guias')} />
      <Category title="Manuales" documents={filteredDocs('manuales')} />
      <Category title="Procedimientos" documents={filteredDocs('procedimientos')} />
      <Category title="Protocolos" documents={filteredDocs('protocolos')} />
    </div>
  );
};

export default Biblioteca;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ref, onValue } from 'firebase/database';
import { getDownloadURL, ref as storageRef } from 'firebase/storage';
import { database, storage } from '../../firebase'; // Asegúrate de que la ruta sea correcta
import { FaFacebook, FaWhatsapp } from 'react-icons/fa'; // Importar íconos
import './NovedadDetalle.css'; // Asegúrate de que la ruta sea correcta

const NovedadDetalle = () => {
  const { id } = useParams();
  const [novedad, setNovedad] = useState(null);

  useEffect(() => {
    const fetchNovedad = async () => {
      const dbRef = ref(database, `novedades/${id}`);
      onValue(dbRef, async (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const imageUrl = await getDownloadURL(storageRef(storage, data.image));
          let videoUrl = '';
          if (data.video) {
            videoUrl = await getDownloadURL(storageRef(storage, data.video));
          }
          setNovedad({ ...data, imageUrl, videoUrl });
        }
      });
    };

    fetchNovedad();
  }, [id]);

  if (!novedad) return <div>Cargando...</div>;

  const shareUrl = window.location.href;
  const shareText = `Mira esta novedad: ${novedad.title}`;

  return (
    <div className="novedad-detalle-container">
      <img src={novedad.imageUrl} alt={novedad.title} className="novedad-detalle-image" />
      <div className="novedad-detalle-content">
        <h1>{novedad.title}</h1>
        <p>{novedad.description}</p>
        <div className="novedad-completo">
          {novedad.completo.split('\n').map((para, index) => (
            <p key={index}>{para}</p>
          ))}
        </div>
        {novedad.videoUrl && (
          <div className="novedad-video-container">
            <video controls className="novedad-video">
              <source src={novedad.videoUrl} type="video/mp4" />
              Tu navegador no soporta la reproducción de videos.
            </video>
          </div>
        )}
        {novedad.fuente && (
          <div className="novedad-fuente">
            <a href={novedad.fuente} target="_blank" rel="noopener noreferrer">
              Leer más
            </a>
          </div>
        )}
        <div className="share-buttons">
          <a 
            href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`} 
            target="_blank" 
            rel="noopener noreferrer"
            title="Compartir en Facebook"
          >
            <FaFacebook size={32} color="#3b5998" />
          </a>
          <a 
            href={`https://wa.me/?text=${encodeURIComponent(shareText)}%20${encodeURIComponent(shareUrl)}`} 
            target="_blank" 
            rel="noopener noreferrer"
            title="Compartir en WhatsApp"
          >
            <FaWhatsapp size={32} color="#25D366" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default NovedadDetalle;

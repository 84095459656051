import React from 'react';
import { FaRocket, FaUsers, FaLightbulb, FaHandshake } from 'react-icons/fa'; // Importing some FontAwesome icons
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import aboutImage from '../Assets/grupo.png'; // Replace with actual path to the image
import teamImage from '../Assets/team.png'; // Replace with actual path to the image
import Footer from '../Front/footer';
import './about.css';

const AboutUs = () => {
  return (
    <div>
    <div className="about-us-container">
      <div className="banner-section">
        <div className="banner-text">
          <h1>Acerca de Nosotros</h1>
          <p>Conoce más sobre nuestra misión, visión, valores y el equipo detrás de nuestro éxito.</p>
          <Link to="/contact" className="contact-button">Contáctanos</Link>
        </div>
        <div className="banner-image">
          <img src={aboutImage} alt="About Us" />
        </div>
      </div>

      <div className="mission-vision-section">
        <div className="mission">
          <FaRocket size={50} color="#2D7CA0" />
          <h2>Nuestra Misión</h2>
          <p>Proveer soluciones innovadoras que optimicen los procesos y potencien el éxito empresarial de nuestros clientes.</p>
        </div>
        <div className="vision">
          <FaLightbulb size={50} color="#2D7CA0" />
          <h2>Nuestra Visión</h2>
          <p>Ser líderes en innovación tecnológica, inspirando cambios positivos y sostenibles en las industrias.</p>
        </div>
      </div>

      <div className="values-section">
        <h2>Nuestros Valores</h2>
        <div className="values">
          <div className="value-item">
            <FaUsers size={40} color="#2D7CA0" />
            <h3>Trabajo en Equipo</h3>
            <p>Fomentamos un ambiente colaborativo donde cada miembro aporta su mejor versión.</p>
          </div>
          <div className="value-item">
            <FaLightbulb size={40} color="#2D7CA0" />
            <h3>Innovación</h3>
            <p>Estamos en constante búsqueda de nuevas ideas y tecnologías que nos permitan avanzar.</p>
          </div>
          <div className="value-item">
            <FaHandshake size={40} color="#2D7CA0" />
            <h3>Integridad</h3>
            <p>Actuamos con transparencia y ética en todas nuestras relaciones y decisiones.</p>
          </div>
        </div>
      </div>

      <div className="team-section">
        <h2>Nuestro Equipo</h2>
        <div className="team-image">
          <img src={teamImage} alt="Nuestro Equipo" />
        </div>
        <p>Conoce a las personas que hacen posible nuestro éxito y están comprometidas con la excelencia en cada proyecto.</p>
      </div>

      <div className="call-to-action-section">
        <h2>¿Quieres saber más?</h2>
        <p>Estamos aquí para ayudarte. Contáctanos y descubre cómo nuestras soluciones pueden transformar tu negocio.</p>
        <Link to="/contact" className="cta-button">Habla con Nosotros</Link>
      </div>
    </div>
    <Footer />
    </div>
  );
};

export default AboutUs;

import React from 'react';
import './MainSection.css';
import image from '../Assets/logo_CHAKUY.png'; // Asegúrate de que esta ruta sea correcta

const MainSection = () => {
  const pdfUrl = 'https://firebasestorage.googleapis.com/v0/b/chakuyweb.appspot.com/o/guias%2FFormato%20-%20Chakuy%20-.pdf?alt=media&token=96b90be4-99ba-4199-89e3-8e4c7feb73bc';

  return (
    <section className="main-section">
      <div className="container">
        <div className="text-container">
          <h1>Innovamos para tu seguridad y éxito empresarial</h1>
          <p>Transforma tu negocio con soluciones de software a la medida y consultoría en seguridad industrial de vanguardia.</p>
          <a href={pdfUrl} download="Formato - Chakuy -.pdf" className="main-button">Descargar guía gratuita</a>
          <p className="question">¿Necesitas más información?  <a href="/contact">Contactanos</a></p>
        </div>
        <div className="image-container">
          <img src={image} alt="Learning" />
        </div>
      </div>
    </section>
  );
};

export default MainSection;

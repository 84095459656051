import React from 'react';
import MainSection from './MainSection';
import FloatingWhatsapp from './FloatingWhatsapp';
//import MainBanner from './mainBanner'; // Asegúrate de que la ruta sea correcta
import MainB2 from './MainB2';
import Cards from './Cards';
import Footer from './footer';
import Novedades from './Novedades'

import './home.css'; // Asegúrate de que la ruta sea correcta

const Home = () => {
  return (
    <div>
   <div className="home-container">
      <MainSection />
      <MainB2 /> {/* Corrige aquí el nombre del componente */}
      <div className="content-section">
        <h1>Ultimas Noticias</h1>
        <p></p>
      </div>
      </div>

      <br></br>
      <Cards />
      <br></br>
      <br></br>
      <div className="content-section">
        <h1>Novedades</h1>
        <p></p>
      </div>
      <Novedades /> 
      <p></p>
      <FloatingWhatsapp /> {/* Asegúrate de que el componente esté dentro del return */}
  <br></br>
  <Footer />
    </div>
  );
};

export default Home;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './DesarrolloSoftware.css';
import softwareImage from '../Assets/desarrollo_software.jpeg'; // Replace with actual path to the image
import chatbotImage from '../Assets/chatbot.png'; // Replace with actual path to the image
import b2bImage from '../Assets/b2b.png'; // Replace with actual path to the image
import bankingImage from '../Assets/banking.png'; // Replace with actual path to the image
import tourismImage from '../Assets/tourism.png'; // Replace with actual path to the image
import restaurantImage from '../Assets/restaurant.png'; // Replace with actual path to the image
import Footer from '../Front/footer';
import { ref, push, set } from 'firebase/database';
import { database } from '../../firebase';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

const DesarrolloSoftware = () => {
  const [formData, setFormData] = useState({
    nombre: '',
    empresa: '',
    pais: '',
    celular: '',
    email: '',
    empleados: '',
    proyecto: '',
    descripcion: '',
    terms: false,
  });

  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const newRef = push(ref(database, 'consultasoftware'));
    await set(newRef, formData);
    setLoading(false);
    setModalIsOpen(true);
    setFormData({
      nombre: '',
      empresa: '',
      pais: '',
      celular: '',
      email: '',
      empleados: '',
      proyecto: '',
      descripcion: '',
      terms: false,
    });
  };

  return (
    <div>
      <div className="desarrollo-software-container">
        <div className="banner-section">
          <div className="banner-text">
            <h1>Software a la medida</h1>
            <p>Mejore y optimice sus procesos operativos en todas las áreas necesarias con nuestra ayuda especializada.</p>
            <Link to="/contact">
              <button className="contact-button">Contactar Asesor</button>
            </Link>
          </div>
          <div className="banner-image">
            <img src={softwareImage} alt="Software a la medida" />
          </div>
        </div>

        <div className="digital-transformation-section">
          <h2>Transformación Digital</h2>
          <h3>Te mostramos paso a paso lo que lograremos juntos.</h3>
          <div className="services">
            <div className="service-item">
              <h4>Digitalización</h4>
              <p>Optimizamos tu productividad.</p>
            </div>
            <div className="service-item">
              <h4>Estrategia</h4>
              <p>Planificamos y ejecutamos objetivos.</p>
            </div>
            <div className="service-item">
              <h4>Experiencia digital</h4>
              <p>Brindamos la mejor experiencia digital.</p>
            </div>
            <div className="service-item">
              <h4>Mejora continua</h4>
              <p>Innovamos en nuevos mercados.</p>
            </div>
          </div>
        </div>

        <div className="industries-section">
          <h2>Tipos de industrias</h2>
          <h3>Nuevos modelos de negocio y experiencias digitales.</h3>
          <div className="industries">
            <div className="industry-item">
              <img src={chatbotImage} alt="Chatbots con WhatsApp" className="industry-icon" />
              <p>Chatbots con WhatsApp</p>
            </div>
            <div className="industry-item">
              <img src={b2bImage} alt="B2B Pagos en línea" className="industry-icon" />
              <p>B2B Pagos en línea</p>
            </div>
            <div className="industry-item">
              <img src={bankingImage} alt="Banca" className="industry-icon" />
              <p>Banca</p>
            </div>
            <div className="industry-item">
              <img src={tourismImage} alt="Operadores Turísticos" className="industry-icon" />
              <p>Operadores Turísticos</p>
            </div>
            <div className="industry-item">
              <img src={restaurantImage} alt="Restaurantes" className="industry-icon" />
              <p>Restaurantes</p>
            </div>
          </div>
        </div>

        <div className="contact-section">
          <h2>¿Buscas Desarrollar Software o App A La Medida?</h2>
          <h3>Creemos juntos tu proyecto de innovación digital, solicita una asesoría gratuita aquí.</h3>
          <form className="contact-form" onSubmit={handleSubmit}>
            <input
              type="text"
              name="nombre"
              placeholder="Nombre"
              value={formData.nombre}
              onChange={handleInputChange}
              required
            />
            <input
              type="text"
              name="empresa"
              placeholder="Empresa"
              value={formData.empresa}
              onChange={handleInputChange}
              required
            />
            <input
              type="text"
              name="pais"
              placeholder="País"
              value={formData.pais}
              onChange={handleInputChange}
              required
            />
            <input
              type="text"
              name="celular"
              placeholder="Celular"
              value={formData.celular}
              onChange={handleInputChange}
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
            <select
              name="empleados"
              value={formData.empleados}
              onChange={handleInputChange}
              required
            >
              <option value="">Número de empleados</option>
              <option value="1-10">1-10 empleados</option>
              <option value="11-50">11-50 empleados</option>
              <option value="51-200">51-200 empleados</option>
              <option value="201+">201+ empleados</option>
            </select>
            <input
              type="text"
              name="proyecto"
              placeholder="Tipo de Proyecto"
              value={formData.proyecto}
              onChange={handleInputChange}
              required
            />
            <textarea
              name="descripcion"
              placeholder="Detalle tu proyecto brevemente"
              value={formData.descripcion}
              onChange={handleInputChange}
              required
            ></textarea>
            <label>
              <input
                type="checkbox"
                name="terms"
                checked={formData.terms}
                onChange={handleInputChange}
                required
              />
              Acepto los Términos y Condiciones.
            </label>
            <button type="submit" disabled={loading}>
              {loading ? <Spinner animation="border" size="sm" /> : 'Enviar mensaje'}
            </button>
          </form>
        </div>
      </div>
      <Footer />
      <Modal show={modalIsOpen} onHide={() => setModalIsOpen(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Consulta Enviada</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Consulta enviada exitosamente.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setModalIsOpen(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DesarrolloSoftware;
